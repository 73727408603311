import $ from 'jquery';
import 'slick-carousel/slick/slick';
import 'objectFitPolyfill';

window.objectFitPolyfill();

import device from "current-device";

const bodyScrollLock = require('body-scroll-lock');
const disableBodyScroll = bodyScrollLock.disableBodyScroll;
const enableBodyScroll = bodyScrollLock.enableBodyScroll;

$(function () {
    $(".collapse dt").on("click", function () {
        $(this).closest(".collapse").toggleClass("open");
        $(this).closest(".collapse").find("dd").slideToggle();
    })
    $(".has-dropdown .show-dropdown").on("click", function () {
        var $parent = $(this).closest(".has-dropdown")
        $parent.siblings().removeClass("open");
        $parent.toggleClass("open");
        $('.sp-dropdown').empty()
        if ($parent.hasClass('open')) {
            var $dropdown = $(this).siblings('.dropdown')
            $('.sp-dropdown').append($dropdown.clone())
        }
    })
    // $(".toc_container .toc_check").on("click", function () {
    //     $(this).closest(".collapse").toggleClass("open");
    //     $(this).closest(".toc_container").find(".toc_list").slideToggle();
    // })
    $(".box-user .user-list ul li a").on("click", function (e) {
        e.preventDefault();
        var target = $(this).attr("href");
        $(".box-user .active").removeClass("active");
        $(this).closest("li").addClass("active");
        $(target).addClass("active");
    })


    // menu
    let fixedMenu = $('.menu-fixed');
    let isLock = false;
    $('.menu-open, .menu-close').on('click', function () {
        fixedMenu.toggleClass('open')
        isLock = !isLock;
        isLock ? disableBodyScroll(fixedMenu[0]) : enableBodyScroll(fixedMenu[0]);
    })
    $(window).on('scroll', function () {
        if ($(window).scrollTop() > 0) {
            $('.header').addClass('scrolled')
        } else {
            $('.header').removeClass('scrolled')
        }
    })

    $('.js-collapse-btn').on('click', '.js-btn', function (e) {
        e.preventDefault()
        let btn = $(this).parent();
        btn.toggleClass('open')
            .siblings('.js-collapse-target')
            .slideToggle('fast')
    })

    //slide
    $('.visual-slider').slick({
        infinite: true,
        slidesToShow: 1,
        arrows: false,
        speed: 500,
        autoplay: true,
        autoplaySpeed: 5000, //Autoplay Speed in milliseconds
        fade: true,
        cssEase: 'linear',
        speed: 500, //Slide/Fade animation speed

    })

    $('.pickup-slider').slick({
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        variableWidth: true,
        prevArrow: $('.pickup-prev'),
        nextArrow: $('.pickup-next'),
    })

    if ($(".user-slider .user-content").length > 1) {
        $(".user-slider").slick({
            infinite: true,
            speed: 500,
            slidesToShow: 1,
        })
    }

    //category
    $("a.go-to").on("click", function (e) {
        e.preventDefault();
        let headerFixed = $('.header').css('position') == 'fixed' ? $('.header').height() + 13 : 0 + 13;
        let target = $(this).attr("href");
        $('html, body').animate({
            scrollTop: $(target).offset().top - headerFixed
        }, 800);
    })

    if($(".user-list ul").outerWidth() > $(".user-list").outerWidth()){
        $(".user-list").addClass("scroll");
    }else{
        $(".user-list").removeClass("scroll");
    }
    $(window).on('resize', function() {
        $(".slider.slick-initialized").slick('reinit');
        if($(".user-list ul").outerWidth() > $(".user-list").outerWidth()){
            $(".user-list").addClass("scroll");
        }else{
            $(".user-list").removeClass("scroll");
        }
    });

    // search page
    $(document).on('click', '.j__accordion-button', function () {
        var $root = $(this).closest('.j__accordion-root')
        $root.find('.a__toggle-icon').toggleClass('open')
        $root.find('.j__accordion-content').slideToggle('fast')
    })

    $(document).on('click', '.field-note', function () {
        var $content = $(this).find('.note-content')
        $content.toggle()
        $('.note-content').not($content).hide()
    })
    $(document).on('click', '.note-content', function(e){
        e.stopPropagation()
    })
    $(document).on('click', function(e) {
        if (!$(e.target).closest('.field-note').length) {
            $('.note-content').hide()
        }
    })
    $('.zip-box').on('click', function() {
        $(this).find('input')[0].focus()
    })
});
